import { SessionProvider } from 'next-auth/react'
import { Provider } from 'react-redux'
import { NextSeo } from 'next-seo'
import store from '../store'
import Script from 'next/script'
import Messenger from '../components/Messenger'
import Loader from '../components/Loader'
import { appWithTranslation } from 'next-i18next'
import '../styles/globals.css'

const getSEOConfig = (seo) => ({
  title: seo?.metaTitle || '百變怪貼紙 - StickerHD',
  description:
    seo?.metaDescription ||
    '百變怪貼紙，專注完美的貼紙印刷 免費線上校稿、少量也能印刷、絕對高品質、防水抗UV 超高速出貨到貨，馬上輕鬆客製化貼紙！',
  openGraph: {
    title: seo?.metaTitle || '百變怪貼紙 - StickerHD',
    description:
      seo?.metaDescription ||
      '百變怪貼紙，專注完美的貼紙印刷 免費線上校稿、少量也能印刷、絕對高品質、防水抗UV 超高速出貨到貨，馬上輕鬆客製化貼紙！',
    type: 'website',
    siteName: 'Sticker HD',
    url: seo?.url || `${process.env.NEXT_PUBLIC_URL}`,
    images: [
      {
        url: seo?.imgUrl || `${process.env.NEXT_PUBLIC_URL}/images/heroImage.webp`,
        width: 1200,
        height: 630,
        alt: seo?.imgAlt || `Sticker HD Main Visual Image`,
      },
    ],
  },
})

const renderApp = ({ Component, pageProps, seo }) => {
  const withLayout = Component.withLayout || ((page) => page)

  const disableSeoComponents = ['Custom404', 'TapPayRedirect', 'InvoiceAndQuotation', 'Order', 'OrderChecking', 'PaymentResult']

  if (disableSeoComponents.includes(Component.name)) {
    return (
      <>
        <NextSeo noindex={true} nofollow={true} />
        <Provider store={store}>
          <Script
            id="gtm-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PZRMKX');`,
            }}
          />
          <SessionProvider session={pageProps.session} refetchInterval={0}>
            <Loader />
            <Messenger />
            {withLayout(<Component {...pageProps} />)}
          </SessionProvider>
        </Provider>
      </>
    )
  }

  return (
    <>
      <NextSeo {...getSEOConfig(seo)} />
      <Provider store={store}>
        <Script
          id="gtm-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PZRMKX');`,
          }}
        />
        <SessionProvider session={pageProps.session} refetchInterval={0}>
          <Loader />
          <Messenger />
          {withLayout(<Component {...pageProps} />)}
        </SessionProvider>
      </Provider>
    </>
  )
}

function App({ Component, pageProps }) {
  return renderApp({ Component, pageProps, seo: pageProps.seo })
}

export default appWithTranslation(App)