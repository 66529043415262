import simpleFetchAPI from '../utils/simpleFetchAPI'

const baseUrl = process.env.contentfulUrl
const accessToken = process.env.contentfulAccessToken

const localeMap = {
  zhHant: 'zh-Hant-TW',
  en: 'en-US',
}

const localeMapping = (locale) => {
  try {
    return localeMap[locale]
  } catch {
    return 'zh-Hant-TW'
  }
}

const getEntryEndpoint = (entryId, locale) => {
  const url = `${baseUrl}/entries/${entryId}?access_token=${accessToken}${locale ? `&locale=${localeMapping(locale)}` : ''}`
  return url
}

const getEntriesEndpoint = (params = {}, locale = undefined) => {
  let url = `${baseUrl}/entries?access_token=${accessToken}${locale ? `&locale=${localeMapping(locale)}` : ''}`

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      url += `&${key}=${encodeURIComponent(value)}`
    }
  })

  return url
}

const getAssetEndpoint = (assetId, locale = undefined) => {
  const url = `${baseUrl}/assets/${assetId}?access_token=${accessToken}${locale ? `&locale=${localeMapping(locale)}` : ''}`
  return url
}

const getAssetsEndpoint = (limit = 300, locale = undefined) => {
  const url = `${baseUrl}/assets?access_token=${accessToken}&limit=${limit}${locale ? `&locale=${localeMapping(locale)}` : ''}`
  return url
}

const getResListByIds = async (iterable, locale = undefined) => {
  const ids = iterable.map((e) => e.sys.id)

  const params = {
    'sys.id[in]': ids.join(','),
  }

  const apiUrl = getEntriesEndpoint(params, locale)
  const res = await simpleFetchAPI(apiUrl)

  return res.items
}

export { getEntryEndpoint, getEntriesEndpoint, getAssetEndpoint, getAssetsEndpoint, getResListByIds }
