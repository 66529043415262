import { useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import CTAModal from '../components/CTAModal'
import media from '../styles/media'

import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { localizeLink } from '../utils/localizationScripts'

const footerBreakpoint = media.md

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default function FooterComponent() {
  const [locale] = useState('zhHant')
  const [modalOpen, setModalOpen] = useState(false)

  const { t } = useTranslation(['common', 'index'])

  return (
    <Footer>
      <CTAModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Navigation>
        <span onClick={() => setModalOpen(true)}>{t('startMyOrder')}</span>
        {/* <Link href="/gallery">貼紙作品參考</Link> */}
        <a href="https://medium.com/stickerhd-%E8%B2%BC%E7%B4%99%E8%A3%BD%E4%BD%9C%E7%A7%98%E8%BE%9B" target="_blank" rel="noreferrer">
          {t('stickerDesignSample')}
        </a>
        <Link href="/faq">{t('commonPrintingQuestions')}</Link>
        {/* <a href="#">公版下載</a> */}
      </Navigation>
      <Information>
        <p dangerouslySetInnerHTML={{ __html: t('operationTime') }}></p>
        <p>
          <span>Email: </span>help@stickerhd.com<br></br>
          <span>{t('phone')}: </span>+886 02-27736106<br></br>
          <span>{t('address')}: </span>
          {t('fullAddress')}
        </p>
      </Information>
      <SocialLinks>
        <a href="https://lin.ee/YQEUkhz" target="_blank" rel="noreferrer">
          <img src="/images/Line.svg" alt="line icon" />
        </a>
        <a href="https://www.instagram.com/stickerhd/" target="_blank" rel="noreferrer">
          <img src="/images/Instagram.svg" alt="instagram icon" />
        </a>
        <a href="https://www.facebook.com/getstickerhd" target="_blank" rel="noreferrer">
          <img src="/images/Facebook.svg" alt="facebook icon" />
        </a>
      </SocialLinks>
      <Placeholder />
      <Placeholder />
      <Copyright>
        <p>© {new Date().getFullYear()} StickerHD</p>
        <div>
          <a href={localizeLink('/document/privacy', locale)} target="_blank" rel="noreferrer">
            {t('privacy')}
          </a>
          <a href={localizeLink('/document/terms', locale)} target="_blank" rel="noreferrer">
            {t('term')}
          </a>
        </div>
      </Copyright>
    </Footer>
  )
}

const Footer = styled.div`
  background-color: var(--primary);
  padding: 40px 117px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 120px;
  row-gap: 24px;
  ${footerBreakpoint`
      grid-template-columns: 1fr;
      padding: 32px 24px;
      row-gap: 32px;
    `}
`
const Placeholder = styled.div`
  ${footerBreakpoint`
      display: none;
    `}
`
const Navigation = styled.div`
  a,
  span {
    cursor: pointer;
    display: block;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-top: 12px;
  }
  ${footerBreakpoint`
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 16px;
      a, span{
        margin-top: 0px;
      }
    `}
`
const Information = styled.div`
  p {
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    margin-top: 12px;
    span {
      font-weight: 600;
    }
    ${footerBreakpoint`
        margin-top: 0px;
        margin-bottom: 0px;
        &:not(:first-of-type){
          margin-top: 12px;
        }
      `}
  }
`
const SocialLinks = styled.div`
  text-align: right;
  display: flex;
  justify-content: end;
  a {
    margin-left: 24px;
  }
  ${footerBreakpoint`
      justify-content: center;
      a:first-of-type{
        margin-left: 0px;
      }
      margin-top: 32px;
    `}
`
const Copyright = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  a {
    margin-left: 16px;
  }
  ${footerBreakpoint`
      display: flex;
      flex-direction: column-reverse;
      p{
        margin-top: 24px;
      }
      a:first-of-type{
        margin-left: 0px;
      }
    `}
`
