import styled from 'styled-components'
import { useEffect, useState } from 'react'
import media from '../styles/media'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

const headerBreakpoint = media.md

const region = [
  {
    value: 'zhHant',
    name: '繁體中文(TW)',
  },
  {
    value: 'en',
    name: 'English(US)',
  },
]

export default function ChangeRegion({ ...props }) {
  const [locale, setLocale] = useState(region[0].value)
  const { i18n } = useTranslation()
  const { asPath } = useRouter()

  const handleChangeLanguage = (e) => {
    const locale = e.target.value
    setLocale(locale)
    window.location = `/${locale}${asPath}`
  }

  useEffect(() => {
    if (!i18n) return
    setLocale(i18n.language)
  }, [i18n])

  return (
    <ChangeRegionDropdown value={locale} onChange={handleChangeLanguage} icon={locale} {...props}>
      {region.map((item, index) => (
        <option key={index} value={item.value}>
          {item.name}
        </option>
      ))}
    </ChangeRegionDropdown>
  )
}
const ChangeRegionDropdown = styled.select`
  font-weight: 600;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--primary);
  border-radius: 40px;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary);
  height: 40px;
  padding: 0px 8px;
  padding-left: 40px;
  background: url(/images/region/${(props) => props.icon}.svg);
  background-repeat: no-repeat;
  background-position: left 4px center;
  background-size: 28px;
  ${headerBreakpoint`
    border: none;
    `}
`
