import Router from 'next/router'
import styled from 'styled-components'
import Layout from '../layouts/default'
import CTAModal from '../components/CTAModal'
import media from '../styles/media'
import { LargeRedButton, LargeGreenButton } from '../components/Buttons/LargeButtons'
import { useState } from 'react'

import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'

import { getCategories, getRecommendProducts, getGalleryItems } from '../service/contentfulAPIs'

export async function getServerSideProps({locale}) {
  const categories = await getCategories(locale)
  const products = await getRecommendProducts({ limit: 12, locale })
  const galleryItems = await getGalleryItems(locale)

  const sampleSlug = process.env.SAMPLE_SLUG

  return {
    props: {
      categories: categories.filter((category) => category.isHidden === false),
      products,
      galleryItems,
      sampleSlug,
      locale,
      ...(await serverSideTranslations(locale, ['common', 'index'])),
    },
  }
}

export default function Home(props) {
  const [modalOpen, setModalOpen] = useState(false)

  const { t } = useTranslation(['common', 'index'])

  return (
    <Main>
      <CTAModal modalOpen={modalOpen} setModalOpen={setModalOpen} locale={props.locale} />
      <HeroImageWrapper>
        <LeftHeroImageContent>
          <h1 dangerouslySetInnerHTML={{ __html: t('index:landingSlogan') }}></h1>
          <p dangerouslySetInnerHTML={{ __html: t('index:landingSubTitle') }}></p>
          <ButtonGroup>
            <LargeRedButton style={{ marginRight: '20px' }} onClick={() => setModalOpen(true)}>
              {t('common:startMyOrder')}
            </LargeRedButton>
            <LargeGreenButton onClick={() => Router.push(`/categories/${props.sampleSlug}`)}>{t('common:getSample')}</LargeGreenButton>
          </ButtonGroup>
        </LeftHeroImageContent>
        {/* TODO: hide in mobile */}
        <RightHeroImageContent>
          <img src="/images/heroImage.webp" alt="main visual image" />
        </RightHeroImageContent>
      </HeroImageWrapper>

      <Section>
        <SectionTitle className="primary">{t('index:productCategoryTitle')}</SectionTitle>
        <ItemWrapper>
          {props.categories.map((category, index) => (
            <Category key={index} onClick={() => Router.push(`/categories/${category.slug}`)}>
              <img src={category.thumbnail} alt="category thumbnail" />
              <h5>{category.name}</h5>
              <p>{category.description}</p>
            </Category>
          ))}
        </ItemWrapper>
      </Section>

      <Section>
        <SectionTitle className="primary">{t('index:popularProducts')}</SectionTitle>
        <ProductWrapper>
          {props.products.map((product, index) => (
            <SmallProduct key={index} onClick={() => Router.push(`/products/${product.slug}`)}>
              <img src={product.thumbnail} alt="product thumbnail" />
              <ProductTitle>{product.title}</ProductTitle>
            </SmallProduct>
          ))}
        </ProductWrapper>
      </Section>
      <PromoteSection>
        <Tutorial>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/o_n4CjzKzSA"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: t('index:promoteTitle') }}></h3>
            <p>{t('index:promoteDescription')}</p>
          </div>
        </Tutorial>
        <OurClients>
          <h3>{t('index:ourClient')}</h3>
          <div>
            <img src="/images/clients/AppWorks.svg" />
            <img src="/images/clients/Google.svg" />
            <img src="/images/clients/ProjectGalaxy.svg" />
            <img src="/images/clients/Dcard.svg" />
            <img src="/images/clients/TWStartup.svg" />
            <img src="/images/clients/ChanChi.svg" />
          </div>
        </OurClients>
      </PromoteSection>
    </Main>
  )
}

Home.withLayout = (pageComponent) => <Layout withFooter={true}>{pageComponent}</Layout>

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Section = styled.div`
  padding: 32px 20px;
`

const HeroImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, var(--primary) 0%, #31b694 100%);
  color: #fff;
  padding: 0px 80px 0;
  position: relative;
  ${media.md`
      flex-direction: column-reverse;
      padding: 16px 12px 24px 12px;
    `}
`
const LeftHeroImageContent = styled.div`
  margin-bottom: 80px;
  padding-top: 80px;
  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    letter-spacing: 1px;
    margin: 0 0 12px;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.75px;
    margin: 0 0 28px;
  }
  ${media.md`
      padding-top: 24px;
      h1{
        font-size: 32px;
        line-height: 44px;
      }
      p{
        font-size: 17px;
        line-height: 28px;
        margin-top: 12px;
        margin-bottom: 32px;
      }
      text-align: center;
      margin-bottom: 50px;
    `}
  ${media.sm`
      text-align: left;
    `}
`
const RightHeroImageContent = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 40%;
  img {
    width: 100%;
  }
  ${media.md`
      width: 100vw;
      margin-left: -12px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      ${media.sm` 
          justify-content: end;
        `}
      img{
        height: 40vh;
        width: auto;
        ${media.sm`
            height: 45vh;
            width: auto;
            transform: translate(15%, 0px);
          `}
      }
    `}
`

const ButtonGroup = styled.div`
  ${media.sm`
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      &>button{
        margin-right: 0px!important;
      }
    `}
`

const SectionTitle = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin: 0px;
  margin-bottom: 32px;
  margin-top: 16px;
`

const PromoteSection = styled(Section)`
  background-color: var(--grayBackground);
  h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: var(--primary);
    margin-top: 0px;
    margin-bottom: 0px;
  }
  ${media.sm`
      h3{
        font-size: 20px;
      }
    `}
`

// const GallerySection = styled(Section)`
//   text-align: center;
//   button {
//     margin-top: 24px;
//     margin-bottom: 16px;
//   }
// `

const Tutorial = styled.div`
  display: flex;
  margin-bottom: 48px;
  align-items: center;
  justify-content: center;
  iframe {
    width: 560px;
    flex-shrink: 0;
    margin-right: 48px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--grayAsh);
    margin-top: 24px;
  }
  ${media.sm`
      display: flex;
      flex-direction: column;
      iframe{
        width: 100%;
        margin-right: 0px;
      }
      h3{
        margin-top: 16px;
      }
      p{
        margin-top: 12px;
      }
    `}
`

const OurClients = styled.div`
  text-align: center;
  margin-bottom: 64px;
  div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
    margin-top: 32px;
    img {
      width: 100%;
    }
    ${media.md`
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 32px;
      `}
    ${media.sm`
        grid-template-columns: 1fr 1fr;
      `}
  }
`

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0px;
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 0px;
  ${media.md`
      grid-template-columns: 1fr 1fr 1fr;
    `}
  ${media.sm`
      grid-template-columns: 1fr 1fr;
    `}
`

const ProductWrapper = styled(ItemWrapper)`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 16px;
  ${media.md`
      grid-template-columns: 1fr 1fr 1fr;
    `}
  ${media.sm`
      grid-template-columns: 1fr 1fr;
    `}
`

const Category = styled.div`
  border-radius: 8px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: var(--grayInput);
  }
  img {
    width: 100%;
  }
  h5 {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 0px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    margin-bottom: 0px;
    text-align: center;
  }
`
const ProductTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 4px;
  line-height: 24px;
`
const SmallProduct = styled(Category)`
  width: auto;
  height: auto;
  margin-right: 12px;
  position: relative;
  padding: 0px 0px 4px;
  img {
    width: 100%;
  }
`
// const Gallery = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   column-gap: 20px;
// `
// const GalleryItem = styled.a`
//   width: 100%;
//   padding-top: 100%;
//   background-image: url(${(props) => props.src});
//   background-repeat: no-repeat;
//   background-size: 100%;
//   transition: background-size 0.5s;
//   background-position: center;
//   &:hover {
//     background-size: 120%;
//   }
// `
